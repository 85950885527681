* {
  --bg-color: #0f0f0e;
  --hover-shadow-color: #ff0909e2;
  --text-color: #fff;
  margin: 0;
  padding: 0;
}

html {
  background: center fixed;
  background-color: var(--bg-color);
  color: var(--text-color);
  background-size: cover;
  font-size: 12pt;
}

html, body {
  min-height: 100svh;
}

#background {
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

html.bg-jpeg-supported #background {
  background-image: url("img/bg5.99699a8b.jpeg");
}

html.bg-avif-supported #background {
  background-image: url("img/bg5.8b003fa6.avif");
}

html.bg-webp-supported #background {
  background-image: url("img/bg5.4e031dc0.webp");
}

main, footer {
  width: 100%;
}

body {
  flex-direction: column;
  display: flex;
}

main {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

header {
  margin: 0 0 4em;
}

header > picture > img {
  border: 0;
  width: 100%;
  max-width: 740px;
  margin: 4em auto .5em;
  display: block;
}

div.slogan {
  text-align: center;
  margin: 0 1em;
  font-family: Jura, Arial, Helvetica, sans-serif;
  font-size: 200%;
}

@media (width <= 425px) {
  div.slogan {
    font-size: 133.333%;
  }
}

#social {
  margin: 1em 2em 4em;
}

#social > ul {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  list-style: none;
  display: flex;
}

#social > ul > li {
  margin: .5em 2em 1em;
  display: inline-block;
}

#social > ul > li > a:hover, #social > ul > li > a:focus {
  filter: drop-shadow(0px 0px 8px var(--hover-shadow-color));
}

#social > ul > li > a > img {
  max-width: 100%;
  height: 3em;
}

#social > ul > li > a[href*="t.me"] > img {
  height: 3.5em;
}

@media (width <= 994px) {
  #social > ul {
    justify-content: flex-start;
    min-width: 180px;
    max-width: 320px;
  }

  #social > ul > li > a[href*="t.me"] > img {
    height: 3em;
  }

  #social > ul > li > a[href*="youtube.com"] > img {
    height: 2.4em;
  }
}

#announcement {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat Alternates, Trebuchet MS, Tahoma, sans-serif;
  font-size: 20pt;
  display: flex;
}

#announcement > p {
  z-index: 10;
}

#announcement > a img {
  width: 24em;
  max-width: 95%;
  margin-top: -4em;
}

@media (width <= 550px) {
  #announcement > a img {
    margin-top: -15%;
  }
}

#listen {
  margin: 1em 2em 4em;
}

#listen > ul {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: .85em;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  list-style: none;
  display: flex;
}

#listen > ul > li {
  display: inline-block;
}

#listen > ul > li > a {
  margin: .5em 0;
  display: inline-block;
}

#listen > ul > li > a:hover, #listen > ul > li > a:focus {
  filter: drop-shadow(0px 0px 6px var(--hover-shadow-color));
}

#listen > ul > li > a > img {
  height: 2.8em;
}

@media (width <= 1257px) {
  #listen > ul {
    justify-content: flex-start;
    max-width: 650px;
  }

  #listen > ul:after {
    content: "";
    flex: auto;
  }
}

@media (width <= 612px) {
  #listen > ul {
    justify-content: flex-start;
    max-width: 160px;
  }

  #listen > ul:after {
    content: "";
    flex: auto;
  }
}

footer {
  width: 100%;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

footer > div {
  max-width: 1200px;
  margin: 0 auto;
}

footer a {
  color: inherit;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer > div > p {
  margin: .5em 2em;
}

footer > div > p.feedback {
  padding-left: 1em;
}

footer > div > p.copyright-announce {
  color: gray;
  padding-left: 1.6em;
  font-size: 80%;
}
/*# sourceMappingURL=index.10e7fbf3.css.map */
