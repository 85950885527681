* {
    margin: 0;
    padding: 0;
    --bg-color: #0f0f0e;
    --hover-shadow-color: rgba(255, 9, 9, 0.885);
    --text-color: #ffffff;
}

html {
    background: fixed center;
    background-color: var(--bg-color);
    background-size: cover;
    font-size: 12pt;
    color: var(--text-color);
}

html, body {
    min-height: 100vh;
    min-height: 100svh;
}

#background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

html.bg-jpeg-supported #background {
    background-image: url(img/bg5.jpg?quality=65);
}

html.bg-avif-supported #background {
    background-image: url(img/bg5.jpg?as=avif);
}

html.bg-webp-supported #background {
    background-image: url(img/bg5.jpg?as=webp);
}


main, footer {
    width: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

header {
    margin: 0 0 4em 0;
}

header>picture>img {
    border: 0;
    max-width: 740px;
    width: 100%;
    margin: 4em auto 0.5em auto;
    display: block;
}

div.slogan {
    font-family: Jura, Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 200%;
    margin: 0 1em;
}

@media(max-width:425px) {
    div.slogan {
        font-size: 133.3333%;
    }
}

#social{
    margin: 1em 2em 4em;
}
#social>ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
#social>ul>li {
    display: inline-block;
    margin: 0.5em 2em 1em 2em;
}
#social>ul>li>a:hover, #social>ul>li>a:focus {
    filter: drop-shadow(0px 0px 8px var(--hover-shadow-color));
}
#social>ul>li>a>img {
    height: 3em;
    max-width: 100%;
}
#social>ul>li>a[href*="t.me"]>img {
    height: 3.5em;
}

@media(max-width:994px) {
    #social>ul {
        max-width: 320px;
        min-width: 180px;
        justify-content: flex-start;
    }
    #social>ul>li>a[href*="t.me"]>img {
        height: 3em;
    }
    #social>ul>li>a[href*="youtube.com"]>img {
        height: 2.4em;
    }
}

#announcement {
    font-family: 'Montserrat Alternates', 'Trebuchet MS', 'Tahoma', sans-serif;
    text-align: center;
    font-size: 20pt;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
#announcement>p {
    z-index: 10;
}
#announcement>a img {
    width: 24em;
    max-width: 95%;
    margin-top: -4em;
}
@media(max-width: 550px) {
    #announcement>a img {
        margin-top: -15%;
    }
}


#listen {
    margin: 1em 2em 4em;
}
#listen>ul {
    list-style: none;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    column-gap: 0.85em;
}
#listen>ul>li {
    display: inline-block;
}
#listen>ul>li>a {
    display: inline-block;
    margin: 0.5em 0 0.5em 0;
}
#listen>ul>li>a:hover, #listen>ul>li>a:focus {
    filter: drop-shadow(0px 0px 6px var(--hover-shadow-color));
}
#listen>ul>li>a>img {
    height: 2.8em;
}

@media(max-width:1257px) {
    #listen>ul {
        justify-content: flex-start;
        max-width: 650px;
    }
    #listen>ul::after {
        content: "";
        flex: auto;
    }
}

@media(max-width:612px) {
    #listen>ul {
        justify-content: flex-start;
        max-width: 160px;
    }
    #listen>ul::after {
        content: "";
        flex: auto;
    }
}

footer {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    width: 100%;
}
footer>div {
    max-width: 1200px;
    margin: 0 auto;
}
footer a {
    color: inherit;
    text-decoration: none;
}
footer a:hover {
    text-decoration: underline;
}
footer>div>p {
    margin: 0.5em 2em 0.5em 2em;
}
footer>div>p.feedback {
    padding-left: 1em;
}
footer>div>p.copyright-announce {
    padding-left: 1.6em;
    color: #808080;
    font-size: 80%;
}
